<template>
  <span style="text-align: center">
    <el-button size="mini" type="primary" @click="start(id)">{{id ? $t('button.edit') : $t('button.add')}}</el-button> &nbsp;
    <el-dialog
      :title="id ? $t('button.edit') : $t('button.add')"
      :visible.sync="dialogVisible"
      width="500px">
      <div v-loading="loading">
        <el-form
          ref="form"
          :rules="rules"
          :model="company"
          label-width="auto"
          size="medium">
          <el-form-item :label="$t('company.name')" prop="name">
            <el-input v-model="company.name" :placeholder="$t('placeholder.input') + $t('company.name')"/>
          </el-form-item>
          <el-form-item :label="$t('company.concat')" prop="concat">
            <el-input v-model="company.concat" :placeholder="$t('placeholder.input') + $t('company.concat')"/>
          </el-form-item>
          <el-form-item :label="$t('company.mobile')" prop="mobile">
            <el-input v-model="company.mobile" :placeholder="$t('placeholder.input') + $t('company.mobile')" :maxlength="11"/>
          </el-form-item>
          <el-form-item :label="$t('company.maxEmployeeCount')" prop="maxEmployeeCount">
            <el-input-number
              v-model="company.maxEmployeeCount"
              :placeholder="$t('placeholder.input') + $t('company.maxEmployeeCount')"
              :min="0"
              :precision="0"
              :max="99999999999"
              controls-position="right"
              class="fit-input-number"
            />
          </el-form-item>
          <el-form-item :label="$t('company.employeePageCount')" prop="employeePageCount">
            <el-input-number
              v-model="company.employeePageCount"
              :placeholder="$t('placeholder.input') + $t('company.employeePageCount')"
              :min="0"
              :precision="0"
              :max="99999999999"
              controls-position="right"
              class="fit-input-number"
            />
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button size="medium" type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
        <el-button size="medium" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {detail, save} from '@/api/company'
import {showSuccessMsg} from "@/utils/func";

export default {
  name: "SaveCompanyBtn",
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      company: {},
      rules: {
        name: [
          {required: true, message: this.$t('placeholder.input') + this.$t('company.name'), trigger: 'blur'},
        ],
        concat: [
          {required: true, message: this.$t('placeholder.input') + this.$t('company.concat'), trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: this.$t('placeholder.input') + this.$t('company.mobile'), trigger: 'blur'},
        ],
        maxEmployeeCount: [
          {required: true, message: this.$t('placeholder.input') + this.$t('company.maxEmployeeCount'), trigger: 'blur'},
        ],
        employeePageCount: [
          {required: true, message: this.$t('placeholder.input') + this.$t('company.employeePageCount'), trigger: 'blur'},
        ]
      },
      setIng: false,
    }
  },
  methods: {
    start(id) {
      this.dialogVisible = true
      this.loading = false
      this.company = {}
      this.setIng = false
      if (id) {
        this.getInfo(id)
      }
    },
    getInfo(id) {
      this.loading = true
      detail({id}).then(res => {
        this.company = res.data
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          
          this.setIng = true
          let data = JSON.parse(JSON.stringify(this.company))
          save(data).then(res => {
            this.dialogVisible = false
            if (this.company.id) {
              showSuccessMsg(this.$t('message.title'), this.$t('message.editSuccess'))
            } else {
              let user = res.data
              showSuccessMsg(this.$t('message.title'), (h => (
                <div>
                  <div>{this.$t('message.addSuccess')}</div>
                  <div>{this.$t('login.nickName')}：{user.name}</div>
                  <div>{this.$t('login.account')}：{user.account}</div>
                  <div>{this.$t('login.password')}：{user.password}</div>
                </div>
              ))(this.$createElement), {
                duration: 10000
              })
            }
            this.$emit('xzdListPageReload', !this.company.id)
          }).finally(() => {
            setTimeout(() => {
              this.setIng = false
            }, 500)
          })
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
.fit-input-number {
  width: 100%;
  
  ::v-deep {
    .el-input__inner {
      text-align: left;
    }
  }
}
</style>