<template>
  <div>
    <el-form size="medium" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item :label="$t('company.name')">
            <el-input v-model.trim="queryParam.name" :placeholder="$t('placeholder.input') + $t('company.name')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('company.concat')">
            <el-input v-model.trim="queryParam.concat" :placeholder="$t('placeholder.input') + $t('company.concat')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('company.mobile')">
            <el-input v-model.trim="queryParam.mobile" :placeholder="$t('placeholder.input') + $t('company.mobile')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0">
            <el-button type="primary" @click="handleSearch(queryParam)">{{$t('button.search')}}</el-button>
            <el-button type="primary" @click="handleSearch({})">{{$t('button.reset')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "CompanyListSearchBox",
  model: {
    prop: 'queryParam',
    event: 'change'
  },
  props: {
    queryParam: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  
  data() {
    return {}
  },
  methods: {
    handleSearch(queryParam) {
      this.$emit('change', queryParam)
      this.$nextTick(() => {
        this.$emit('onSearch')
      })
    },
  },
}
</script>
<style scoped>
</style>